var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "goCenter" }, [
    _vm.showOK
      ? _c(
          "div",
          { staticClass: "w-100" },
          [
            _c(
              "vs-row",
              { staticClass: "justify-content-center" },
              [
                _c(
                  "vs-col",
                  {
                    staticClass:
                      "d-flex justify-content-center align-items-center text-center",
                    attrs: { "vs-w": "6" }
                  },
                  [
                    _c("vs-card", [
                      _c(
                        "div",
                        {
                          staticClass: "textColor",
                          attrs: { slot: "header" },
                          slot: "header"
                        },
                        [
                          _c("unicon", {
                            attrs: {
                              name: "check-circle",
                              fill: "limegreen",
                              width: "85px",
                              height: "85px"
                            }
                          }),
                          _c("h2", [
                            _vm._v(
                              "\n              Operación completada.\n            "
                            )
                          ])
                        ],
                        1
                      )
                    ])
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      : _c(
          "div",
          { staticClass: "w-100" },
          [
            _c(
              "vs-row",
              { staticClass: "justify-content-center" },
              [
                _c(
                  "vs-col",
                  {
                    staticClass:
                      "d-flex justify-content-center align-items-center text-center",
                    attrs: { "vs-w": "6" }
                  },
                  [
                    _c("vs-card", [
                      _c(
                        "div",
                        {
                          staticClass: "textColor",
                          attrs: { slot: "header" },
                          slot: "header"
                        },
                        [
                          _c("unicon", {
                            attrs: {
                              name: "times-circle",
                              fill: "red",
                              width: "85px",
                              height: "85px"
                            }
                          }),
                          _c("h2", [
                            _vm._v(
                              "\n              ¡Opss! Ocurrió un error\n            "
                            )
                          ])
                        ],
                        1
                      ),
                      _c("div", [
                        _c("h5", [
                          _vm._v(
                            "No se pudo llevar a cabo la acción requerida, intentelo más tarde."
                          )
                        ])
                      ])
                    ])
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }