<template>
  <div class="goCenter">
    <div class="w-100" v-if="showOK">
      <vs-row class="justify-content-center">
        <vs-col class="d-flex justify-content-center align-items-center text-center" vs-w="6">
          <vs-card>
            <div slot="header" class="textColor">
              <unicon name="check-circle" fill="limegreen" width="85px" height="85px"></unicon>
              <h2>
                Operación completada.
              </h2>
            </div>
          </vs-card>
        </vs-col>
      </vs-row>
    </div>
    <div class="w-100" v-else>
      <vs-row class="justify-content-center">
        <vs-col class="d-flex justify-content-center align-items-center text-center" vs-w="6">
          <vs-card>
            <div slot="header" class="textColor">
              <unicon name="times-circle" fill="red" width="85px" height="85px"></unicon>
              <h2>
                ¡Opss! Ocurrió un error
              </h2>
            </div>
            <div>
              <h5>No se pudo llevar a cabo la acción requerida, intentelo más tarde.</h5>
            </div>
          </vs-card>
        </vs-col>
      </vs-row>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      token: '',
      showOK: true,
      initScreen: true,
    }
  },
  async mounted() {
    this.token = this.$route.params.wjjn
    if (this.initScreen) {
      this.responseLocal()
    }
  },
  methods: {
    responseLocal() {
      this.$vs.loading()
      this.initScreen = false
      const responseData = {
        wjjn: this.token
      }
      this.$axios.post("/r7gr5lqq4cd9bezd36lwfpndoa8uojsys1mn4ijgd1icxj000sl5rvki825u98yk3bv2hdctllpnrhqq3o83xojbdktrrl0556zg0ngmka5ti7eeabz4bwdshmb291r1nsystcx7b7v432aq9s66w2cenuk1oa2tk98z9uaa536ftdzqlf8agqz7mwjammvjevdb27qnc319yupynz0io79jcz0lzfa", responseData, {}).then(response => {
        if (response.status === 200) {
          if (response.data.msg === "OK") {
            this.showOK = true
            this.$vs.loading.close()
          } else {
            this.showOK = false
            this.$vs.loading.close()
          }
        } else {
          this.showOK = false
          this.$vs.loading.close()
        }
      }).catch(() => {
        this.showOK = false
        this.$vs.loading.close()
      })
    },
  }
}
</script>

<style>
.goCenter {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: center no-repeat
    url("../../assets/images/pages/vuesax-login-bg.jpg");
}
.textColor > h2 {
  color: #2f1b7a;
}
</style>